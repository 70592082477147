import { richText, stripRichTextWrapperTag } from '../../utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';

export default (
  { primary, items },
  pageData,
  { $enhancedLinkSerializer, route }
) => {
  const isClientPage = route.path === '/espace-assure';

  return {
    titleTag: primary.titleTag ? 'h1' : 'h2',
    title: stripRichTextWrapperTag(primary.title),
    subtitle: primary.subtitle,
    description: richText(primary.description),
    cards: items.map(({ label, icon, link }) => ({
      label,
      icon: imageAdapter(icon),
      link: $enhancedLinkSerializer(link),
    })),
    isSingleColumn: !!isClientPage,
    hasSmallMargin: !!isClientPage,
  };
};
